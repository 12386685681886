import React, { Suspense } from 'react';
// import PropTypes from 'prop-types';

import {
  Loading,
  NotFoundPage,
  UnavailablePage,
} from 'murder-hornet-ui';
import { createBrowserHistory as createHistory } from 'history';
import { Navigate, Route, Routes } from 'react-router-dom';

import {
  AppWrapper,
  CustomRouter,
  lazyWithRetry,
  PrivateRoute,
  PublicRoute,
  ScrollToTop,
} from './helpers';

// LANDING
const HomePage = lazyWithRetry(() => import('../components/HomePage'));

// AUTH
const LogIn = lazyWithRetry(() => import('../components/auth/LogIn'));
const Register = lazyWithRetry(() => import('../components/auth/Register'));
const ValidateMFA = lazyWithRetry(() => import('../components/auth/ValidateMFA'));
const ForgotPassword = lazyWithRetry(() => import('../components/auth/ForgotPassword'));
const ResetPassword = lazyWithRetry(() => import('../components/auth/ResetPassword'));

// DASHBOARD
const Dashboard = lazyWithRetry(() => import('../components/Dashboard'));

// ORGANIZATIONS
const IndexOrganizations = lazyWithRetry(() => import('../components/organizations/IndexOrganizations'));
const ViewOrganization = lazyWithRetry(() => import('../components/organizations/ViewOrganization'));
const OrgDetails = lazyWithRetry(() => import('../components/organizations/tabs/OrgDetails'));
const FeatureFlags = lazyWithRetry(() => import('../components/organizations/tabs/FeatureFlags'));
const OrgUsers = lazyWithRetry(() => import('../components/organizations/tabs/OrgUsers'));
const InviteOrgUser = lazyWithRetry(() => import('../components/organizations/InviteOrgUser'));
const DetailOrgUser = lazyWithRetry(() => import('../components/organizations/DetailOrgUser'));

// HELP TICKETS
// const HelpTickets = lazyWithRetry(() => import('../components/help/help-tickets/HelpTickets'));
// const CreateHelpTicket = lazyWithRetry(() => import('../components/help/help-tickets/CreateHelpTicket'));
// const ViewHelpTicket = lazyWithRetry(() => import('../components/help/help-tickets/ViewHelpTicket'));
// const HelpTicketDetails = lazyWithRetry(() => import('../components/help/help-tickets/tabs/HelpTicketDetails'));
// const HelpTicketActivity = lazyWithRetry(() => import('../components/help/help-tickets/tabs/HelpTicketActivity'));
// const HelpTicketCommunications = lazyWithRetry(() => import('../components/help/help-tickets/tabs/HelpTicketCommunications'));

// ERROR LOGS
const ErrorLogs = lazyWithRetry(() => import('../components/help/error-logs/ErrorLogs'));
const ErrorLogDetail = lazyWithRetry(() => import('../components/help/error-logs/ErrorLogDetail'));

// FAQS
const FAQs = lazyWithRetry(() => import('../components/help/faqs/FAQs'));
const CreateFAQ = lazyWithRetry(() => import('../components/help/faqs/CreateFAQ'));
const UpdateFAQ = lazyWithRetry(() => import('../components/help/faqs/UpdateFAQ'));

// TOS
const TOS = lazyWithRetry(() => import('../components/help/tos/TOS'));
const CreateTOS = lazyWithRetry(() => import('../components/help/tos/CreateTOS'));
const TOSDetail = lazyWithRetry(() => import('../components/help/tos/TOSDetail'));

// RELEASE NOTES
const ReleaseNotes = lazyWithRetry(() => import('../components/help/release-notes/ReleaseNotes'));
const CreateReleaseNotes = lazyWithRetry(() => import('../components/help/release-notes/CreateReleaseNotes'));
const ReleaseNotesDetail = lazyWithRetry(() => import('../components/help/release-notes/ReleaseNotesDetail'));

// MY SETTINGS
const IndexMySettings = lazyWithRetry(() => import('../components/settings/my-settings/IndexMySettings'));
const PersonalInformation = lazyWithRetry(() => import('../components/settings/my-settings/PersonalInformation'));
const ResetPasswordLoggedIn = lazyWithRetry(() => import('../components/settings/my-settings/ResetPassword'));
const Sessions = lazyWithRetry(() => import('../components/settings/my-settings/Sessions'));
const Notifications = lazyWithRetry(() => import('../components/settings/my-settings/Notifications'));

// // SYSTEM SETTINGS
// const SystemSettings = lazyWithRetry(() => import('../components/settings/system-settings/SystemSettings'));
// const OrganizationDetails = lazyWithRetry(() => import('../components/settings/system-settings/OrganizationDetails'));
// const Theme = lazyWithRetry(() => import('../components/settings/system-settings/Theme'));

// USERS
const AllCSUsers = lazyWithRetry(() => import('../components/settings/users/AllCSUsers'));
const UserDetail = lazyWithRetry(() => import('../components/settings/users/ViewUser'));
const InviteUser = lazyWithRetry(() => import('../components/settings/users/InviteUser'));
const UserPermissionGroups = lazyWithRetry(() => import('../components/settings/users/UserPermissionGroups'));

// // HELP
// const Help = lazyWithRetry(() => import('../components/settings/help/Help'));
// const ReleaseNotes = lazyWithRetry(() => import('../components/settings/help/ReleaseNotes'));
// const HelpTickets = lazyWithRetry(() => import('../components/settings/help/HelpTickets'));
// const NewHelpTicket = lazyWithRetry(() => import('../components/settings/help/NewHelpTicket'));

// TEST
const TestPage = lazyWithRetry(() => import('../components/TestPage'));

export const history = createHistory();



const AppRouter = () => (
  <CustomRouter history={history}>
    <ScrollToTop>
      <div className='container-scroller'>

        <AppWrapper>
          <Suspense fallback={<Loading main />}>
            <Routes>
              {/* LANDING */}
              <Route path='/' element={<PublicRoute><HomePage /></PublicRoute>} />

              {/* AUTH */}
              <Route path='/login' element={<PublicRoute><LogIn /></PublicRoute>} />
              <Route path='/register/:token_uuid' element={<PublicRoute><Register /></PublicRoute>} />
              <Route path='/validate/session' element={<PublicRoute><ValidateMFA /></PublicRoute>} />
              <Route path='/forgot-password' element={<PublicRoute><ForgotPassword /></PublicRoute>} />
              <Route path='/reset-password/:token_uuid' element={<PublicRoute><ResetPassword /></PublicRoute>} />

              {/* DASHBOARD */}
              <Route path='/dashboard' element={<PrivateRoute><Dashboard /></PrivateRoute>} />

              {/* ORGANIZATIONS */}
              <Route path='/organizations' element={<PrivateRoute perform={['cs:organizations:read-all', 'cs:organizations:read-assigned']}><IndexOrganizations /></PrivateRoute>} />
              <Route path='/organizations/:organization_uuid' element={<PrivateRoute perform={['cs:organizations:read-all', 'cs:organizations:read-assigned']}><ViewOrganization /></PrivateRoute>}>
                <Route path='details' element={<OrgDetails />} />
                <Route path='feature-flags' element={<FeatureFlags />} />
                <Route path='users' element={<OrgUsers />} />
              </Route>
              <Route path='/organizations/:organization_uuid/users/invite' element={<InviteOrgUser />} />
              <Route path='/organizations/:organization_uuid/users/:user_type/:user_uuid' element={<PrivateRoute perform={['cs:organizations:read-all', 'cs:organizations:read-assigned']}><DetailOrgUser /></PrivateRoute>} />

              {/* HELP TICKETS */}
              {/* <Route path='/help-tickets' element={<PrivateRoute perform='cs:help-tickets:read'><HelpTickets /></PrivateRoute>} />
              <Route path='/help-tickets/new' element={<PrivateRoute perform='cs:help-tickets:create'><CreateHelpTicket /></PrivateRoute>} />
              <Route path='/help-tickets/:help_ticket_uuid' element={<PrivateRoute perform='cs:help-tickets:read'><ViewHelpTicket /></PrivateRoute>}>
                <Route path='details' element={<HelpTicketDetails />} />
                <Route path='activity' element={<HelpTicketActivity />} />
                <Route path='communications' element={<HelpTicketCommunications />} />
              </Route> */}

              {/* ERROR LOGS */}
              <Route path='/error-logs' element={<PrivateRoute perform='cs:errors:read'><ErrorLogs /></PrivateRoute>} />
              <Route path='/error-logs/:error_uuid' element={<PrivateRoute perform='cs:errors:read'><ErrorLogDetail /></PrivateRoute>} />

              {/* FAQS */}
              <Route path='/faqs' element={<Navigate to='/faqs/processor' />} />
              <Route path='/faqs/:user_type' element={<PrivateRoute perform='cs:faq:read'><FAQs /></PrivateRoute>} />
              <Route path='/faqs/:user_type/new' element={<PrivateRoute perform='cs:faq:edit'><CreateFAQ /></PrivateRoute>} />
              <Route path='/faqs/:user_type/edit/:faq_uuid' element={<PrivateRoute perform='cs:faq:edit'><UpdateFAQ /></PrivateRoute>} />

              {/* RELEASE NOTES */}
              <Route path='/release-notes' element={<Navigate to='/release-notes/processor' />} />
              <Route path='/release-notes/:user_type' element={<PrivateRoute perform='cs:release-notes:read'><ReleaseNotes /></PrivateRoute>} />
              <Route path='/release-notes/:user_type/:notes_uuid' element={<PrivateRoute perform='cs:release-notes:read'><ReleaseNotesDetail /></PrivateRoute>} />
              <Route path='/release-notes/:user_type/new' element={<PrivateRoute perform='cs:release-notes:edit'><CreateReleaseNotes /></PrivateRoute>} />

              {/* TOS */}
              <Route path='/tos' element={<Navigate to='/tos/processor' />} />
              <Route path='/tos/:user_type' element={<PrivateRoute perform='cs:tos:read'><TOS /></PrivateRoute>} />
              <Route path='/tos/:user_type/:tos_uuid' element={<PrivateRoute perform='cs:tos:read'><TOSDetail /></PrivateRoute>} />
              <Route path='/tos/:user_type/new' element={<PrivateRoute perform='cs:tos:edit'><CreateTOS /></PrivateRoute>} />

              {/* MY SETTINGS */}
              <Route path='/my-settings'>
                <Route index element={<PrivateRoute><IndexMySettings /></PrivateRoute>} />
                <Route path='personal-information' element={<PrivateRoute><PersonalInformation /></PrivateRoute>} />
                <Route path='reset-password' element={<PrivateRoute><ResetPasswordLoggedIn /></PrivateRoute>} />
                <Route path='sessions' element={<PrivateRoute><Sessions /></PrivateRoute>} />
                <Route path='notifications' element={<PrivateRoute><Notifications /></PrivateRoute>} />
              </Route>

              {/* SYSTEM SETTINGS */}
              {/* <Route path='system-settings'>
                  <Route index  element={<PrivateRoute><SystemSettings /></PrivateRoute>} />
                  <Route path='organization' element={<PrivateRoute><OrganizationDetails /></PrivateRoute>} />
                  <Route path='theme' element={<PrivateRoute><Theme /></PrivateRoute>} />
                </Route> */}

              {/* USERS */}
              <Route path='users'>
                <Route index element={<PrivateRoute perform='cs:cs-user:read'><AllCSUsers /></PrivateRoute>} />
                <Route path=':user_uuid' element={<PrivateRoute perform='cs:cs-user:read'><UserDetail /></PrivateRoute>} />
                <Route path='invite' element={<PrivateRoute perform='cs:cs-user:invite'><InviteUser /></PrivateRoute>} />
                <Route path='permissions' element={<PrivateRoute perform='cs:cs-user:read'><UserPermissionGroups /></PrivateRoute>} />
              </Route>

              {/* TEST */}
              {environment !== 'production' && <Route path='/test' element={<TestPage />} />}

              {/* 503 Error Redirect */}
              <Route path='/unavailable' element={<UnavailablePage />} />

              <Route path='*' element={<NotFoundPage />} />
            </Routes>
          </Suspense>
        </AppWrapper>

        {/* <Footer
            config={{ footer: config.footer, faqPage: config.faqPage, reportFraudPage: config.reportFraudPage }}
            version={VERSION}
            environment={environment}
            googleTranslate={<GoogleTranslate />} /> */}
        {environment !== 'production' && <div className='position-absolute float-end end-0'>Version {VERSION}</div>}
      </div>
    </ScrollToTop>
  </CustomRouter>
);


AppRouter.propTypes = {
};


export default AppRouter;
